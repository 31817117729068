import React from 'react';
import { OtherContentsPresenter } from 'js/components/common/layout/EtcContents/OtherContents/presenter';
import { useTaskAlertOthercontents } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useTaskAlertOthercontents';

type Item = {
  name: string;
  itemUrl: string;
  hasNotification?: boolean;
};

export type Contents = Item[];

export type Props = {
  isOpen: boolean;
};

export const OtherContents: React.FC<Props> = ({ isOpen }) => {
  const { taskAlertOtherContents } = useTaskAlertOthercontents(isOpen);
  const contents: Contents = [
    {
      name: 'ポイント懸賞',
      itemUrl: '/contents/prize/',
      hasNotification: taskAlertOtherContents.includes('prize'),
    },
    {
      name: 'ポイントUPチャレンジ',
      itemUrl: '/contents/point_up_challenge/',
    },
    {
      name: 'お得メール',
      itemUrl: '/mypage/magazine/',
    },
    {
      name: 'ボーナスポイント',
      itemUrl: '/members_club/',
    },
    {
      name: 'ECナビカードプラス',
      itemUrl: '/card_plus/',
    },
    {
      name: 'ECナビ募金',
      itemUrl: '/smile_project/',
    },
    {
      name: 'ECナビ比較',
      itemUrl: 'https://hikaku.ecnavi.jp/',
    },
  ];

  return <OtherContentsPresenter isOpen={isOpen} contents={contents} />;
};
