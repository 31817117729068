import React from 'react';
import {
  AffiliateSimpleListPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateSimpleList/presenter';

export type Props = PresenterProps;

export const AffiliateSimpleList: React.FC<Props> = (args) => (
  <AffiliateSimpleListPresenter {...args} />
);
